import React, { useEffect, useMemo, useRef } from 'react';
import { useLoader } from '@react-three/fiber';
import * as THREE from 'three';
import { gsap } from 'gsap';

const vertexShader = `
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
  }
`;

const fragmentShader = `
  uniform sampler2D sourceImage;
  uniform sampler2D depthMap;
  uniform vec2 mouse;

  varying vec2 vUv;

  void main() {
    vec2 uv = vUv;

    vec4 depth = texture2D(depthMap, uv);
    gl_FragColor = texture2D(sourceImage, uv + mouse * depth.r);
  }
`;

const ImageParallaxer = ({ dimensions }) => {
  const sourceImage = useLoader(THREE.TextureLoader, '/webgl/hero.jpg');
  const depthMap = useLoader(THREE.TextureLoader, '/webgl/hero-depth.jpg');

  const aspectRatio = sourceImage.image.width / sourceImage.image.height;

  const scale = dimensions.normalizedHeight * 1.1;

  const materialRef = useRef();
  const uniforms = useMemo(() => {
    return {
      sourceImage: { value: sourceImage },
      depthMap: { value: depthMap },
      mouse: { value: new THREE.Vector2(0, 0) }
    };
  }, [sourceImage, depthMap]);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return () => {};
    }

    const xCursorDivisor = 0.05;
    const yCursorDivisor = 0.05;

    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      const { width, height } = dimensions;
      const x = (clientX / width) * 2 - 1;
      const y = -(clientY / height) * 2 + 1;

      gsap.killTweensOf(uniforms.mouse.value);

      gsap.to(uniforms.mouse.value, {
        duration: 1,
        x: x * xCursorDivisor,
        y: y * yCursorDivisor,
        ease: 'power4.out'
      });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [dimensions, uniforms.mouse]);

  return (
    <group>
      <mesh>
        <planeGeometry attach="geometry" args={[aspectRatio * scale, scale]} />
        <shaderMaterial
          ref={materialRef}
          attach="material"
          vertexShader={vertexShader}
          fragmentShader={fragmentShader}
          uniforms={uniforms}
          toneMapped={false}
        />
      </mesh>
    </group>
  );
};

export default ImageParallaxer;
