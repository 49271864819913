import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import cn from 'classnames';
import * as styles from './styles.module.scss';
// import { parseImagesFromFigma } from '@lam-agency/toolkit/utils/figma';
import {
  ATFWidget,
  LayoutProvider,
  TAnimation,
  TextAnimation
} from '@lam-agency/toolkit/components';
import { getCSSVariableValue } from '@lam-agency/toolkit/utils';

import ImageParallaxer from './ImageParallaxer';
import { IWidgetProps } from '@lam-agency/toolkit/components/Common/ATFWidget';

// import ImagePlane from './ImagePlane';
// import Effects from './Effects';

interface IFields extends IWidgetProps {
  body: string;
  images: string[];
}
interface IProps {
  data: {
    fields: IFields;
  };
}

const FigmaCopyOnImage = ({
  data: {
    fields: { assetLinks, contactURL, quickLinks }
  }
}: IProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [dimensions, setDimensions] = useState({
    width: 0,
    normalizedWidth: 0,
    height: 0,
    normalizedHeight: 0,
    aspect: 0
  });

  const textAnimation = getCSSVariableValue(
    '--atf-text-animation'
  ) as TAnimation;

  //

  const pixelsToCamera = (pixelWidth: number) => {
    const SCALE_FACTOR = 0.01;

    return pixelWidth * SCALE_FACTOR;
  };

  //

  useEffect(() => {
    if (!containerRef?.current || typeof window === `undefined`) {
      return;
    }

    const handleResize = () => {
      if (!containerRef.current) return;

      const boundingRect = containerRef.current.getBoundingClientRect();

      const { width, height } = boundingRect;

      const aspect = width / height;
      const cameraWidth = pixelsToCamera(width);

      setDimensions({
        width,
        height,
        aspect,
        normalizedWidth: cameraWidth,
        normalizedHeight: cameraWidth * (1 / aspect)
      });
    };

    window.addEventListener(`resize`, handleResize);

    handleResize();

    return () => {
      window.removeEventListener(`resize`, handleResize);
    };
  }, []);

  //

  return (
    <>
      <div ref={containerRef} className={styles.container}>
        <div className={styles.background}>
          <Canvas
            camera={{ position: [0, 0, 100], near: 0, far: 1000, zoom: 100 }}
            orthographic
          >
            <Suspense fallback={null}>
              <ImageParallaxer dimensions={dimensions} />
            </Suspense>
          </Canvas>
        </div>

        <LayoutProvider grid paddingX paddingY className={styles.layout}>
          <div className={styles.textWrapper}>
            <TextAnimation
              className={cn('d1', styles.text)}
              text={`Advancing\nBaw Baw`}
              animation={textAnimation}
              speed={0.03}
            />
          </div>

          <ATFWidget
            contactURL={contactURL}
            assetLinks={assetLinks}
            quickLinks={quickLinks}
          />
        </LayoutProvider>
      </div>

      <div aria-hidden className={styles.scrollPadding} />
    </>
  );
};

export default FigmaCopyOnImage;
